  // 上传授权书弹出框
<template>
  <base-dialog
    :visible.sync="visibleState"
    :showFooter="false"
    title="上传授权书"
    class="updataBox"
  >
    <base-form
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      :showBtns="false"
      class="formStyle"
      ref="fromdata"
    >
    </base-form>
    <template slot="footer">
      <base-button label="提交" @click="submitFileList" />
      <base-button label="取消" @click="visibleState = false" type="default" />
    </template>
  </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseForm from '@/components/common/base-form//base-form'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { fromConfig } from '../utils/upload-config'
import { certifyApi } from '@/api/companyApi'
export default {
  props: {
    visible: Boolean,
    keyId: String
  },
  data () {
    return {
      queryParas: {},
      query: { }
    }
  },
  created () {
    this.query.sealAdminId = this.keyId
  },
  components: { BaseDialog, BaseButton, BaseForm },
  computed: {
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
        if (!val) {
          this.query = {}
          this.query.sealAdminId = this.keyId
          this.queryParas = {}
          this.$nextTick(() => {
            this.$refs.fromdata.clearValidate()
          })
        }
      }
    },
    from () {
      return fromConfig(this)
    }
  },
  watch: {
    visible (val) {
      if (val) {
      }
    },
    keyId (val) {
      if (val) {
        this.query.sealAdminId = val
      }
    }
  },
  methods: {
    // 上传附件
    uploadAuthorization (data) {
      this.query.authFileId = data.keyId
      this.$set(this.queryParas, 'authFileName', data.fileName)
    },
    uploadSignAuthFile (data) {
      this.query.signAuthFileId = data.keyId
      this.$set(this.queryParas, 'signAuthFileName', data.fileName)
    },
    // 附件提交
    submitFileList () {
      console.log(this.query)
      this.$refs.fromdata.validate(valid => {
        if (valid) {
          certifyApi.uploadAuthorization(this.query)
            .then(res => {
              if (res.data) {
                this.$parent.handleFilter()
                this.success('提交成功')
                this.visibleState = false
              }
            })
        } else {
          this.error('请完整上传')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.updataBox {
  margin-top: 200px;
  height: 400px;
  display: flex;

  /deep/ .el-dialog {
    width: 50% !important;
    /deep/ .el-dialog__body {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
