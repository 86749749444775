// 上传按钮
<template>
  <!-- 附件上传框 -->
  <div class="accessory">
    <el-input v-bind="$attrs" class="el_input" :disabled="true"/>
    <base-button :http-request="UploadFile" :btnType="'upload'" :label="$attrs.value?'重新上传':'附件上传'" class="el_upload" :icon=" $attrs.value?'iconfont iconzhongxinshangchuan':'iconfont iconshangchuan'"/>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { fileAPi } from '@/api/fileApi'
export default {
  components: { BaseButton },
  methods: {
    UploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then(res => {
        if (res.success) {
          this.$emit('uploadFile', res.data)
          this.success('文件上传成功')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.accessory {
  display: flex;
  .el_input {
    flex: 1;
  }
  .el_upload {
    margin-left: 20px;
    /deep/ .el-upload{
      width: 100%;
    }
  }
}
</style>
