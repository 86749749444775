import uploadFiles from '../components/upload-files.vue'

export const fromConfig = (content) => {
  return [
    {
      label: '授权书',
      prop: 'authFileName',
      attrs: {
        placeholder: '请上传'
      },
      tag: uploadFiles,
      rules: [{ required: true, message: '请上传授权书', trigger: ['blur', 'change'] }],
      span: 18,
      on: {
        uploadFile: (data) => {
          content.uploadAuthorization(data)
        }
      }
    },
    {
      label: '签署证明',
      prop: 'signAuthFileName',
      attrs: {
        placeholder: '请上传'
      },
      tag: uploadFiles,
      rules: [{ required: false, message: '请上传签署证明', trigger: ['blur', 'change'] }],
      span: 18,
      on: {
        uploadFile: (data) => {
          content.uploadSignAuthFile(data)
        }
      }
    }
  ]
}
